<template>
  <div>
    <b-button class="btn-detail mb-2 " v-if="$eliminar(['Detalle de Orden']) && archivos.length"
      @click="showDeleteAllFiles()"
      v-b-popover.hover="'Eliminar todos los archivos'">
      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon"/> Eliminar todo
    </b-button>
    <vue-good-table
    styleClass="vgt-table condensed site-table2"
    :line-numbers="true"
    :search-options="{
      enabled: true
    }"
    :columns="columns"
    :rows="archivos">
      <div slot="emptystate">
        <div class="empty-image">
          <img src="@/assets/empty.svg" alt="Empty">
          <p class="empty-description">Sin archivos cargados</p>
        </div>
      </div>
      <template slot="table-row" slot-scope="props">
        <div>
          <span v-if="props.column.field == 'Acciones'">
            <div class="btn-action-md">
              <b-button class="btn-detail mr-1"
                @click="$descargar(['Detalle de Orden']) && props.row.ArchivoDetId ? viewFile(props.row.ArchivoDetId, props.row.Metadata) : undefined"
                v-b-popover.hover="'Ver Archivo y Metadata'">
                <font-awesome-icon v-if="loadingViewId == props.row.ArchivoDetId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
                <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'eye', }" class="icon btn-icon"/>
               Ver</b-button>
              <b-button class="btn-detail mr-1"
                @click="$descargar(['Detalle de Orden']) && props.row.ArchivoDetId ? downloadFile(props.row.ArchivoDetId, props.row.NomArchivo) : undefined"
                v-b-popover.hover="'Descargar archivo'">
                <font-awesome-icon v-if="loadingDownId == props.row.ArchivoDetId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
                <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'download', }" class="icon btn-icon"/>
               Descargar</b-button>
              <b-button class="btn-detail" v-if="$eliminar(['Detalle de Orden'])"
                @click="props.row.ArchivoDetId ? showDeleteFile(props.row.ArchivoDetId) : undefined"
                v-b-popover.hover="'Eliminar archivo'">
                <font-awesome-icon v-if="loadingDelId == props.row.ArchivoDetId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
                <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon"/>
               </b-button>
            </div>
          </span>
          <span v-else-if="props.column.field === 'Fecha'" class="pointer"
            v-b-popover.hover.html="`<strong>Fecha:</strong> ${props.row.Fecha}<br><strong>Autor:</strong> ${props.row.Autor}`">
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-else-if="props.column.field === 'NomArchivo'" class="pointer"
            v-b-popover.hover.html="`<strong>Archivo:</strong> ${props.row.NomArchivo}`">
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-else-if="props.column.field === 'NomEmpleado'" class="pointer"
            v-b-popover.hover.html="`<strong>Archivo:</strong> ${props.row.NomArchivo}`">
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-else-if="props.column.field == 'Estatus'">
            <span class="icon-msg" v-b-popover.hover="props.row.Estatus"
              @click="props.row.ValidacionManual ? openEstatusModal(props.row.ArchivoDetId, props.row.NomArchivo) : getValidaciones(props.row.ArchivoDetId, props.row.NomArchivo)">
              <font-awesome-icon class="icon"
                :icon="{ prefix: 'fa', iconName: props.row.EstatusIcon}"
                :style="{color: props.row.EstatusColor}"/>
            </span>
          </span>
          <span v-else class="ellipsis-300">
            {{ props.formattedRow[props.column.field] }}
          </span>
        </div>
      </template>
    </vue-good-table>

    <b-modal
        id="bv-modal-viewfile" size="xl" centered hide-footer content-class="site-modal modal-viewer"
        :header-text-variant="'light'">
        <template #modal-title>Visualizar</template>
        <b-card class="min-padd" no-body>
          <b-tabs class="tabs-upload" small card>

            <b-tab title="Archivo">
              <div v-if="srcFile && !(loadingViewId > 0)">
                <prism class="line-numbers"  v-if="xmlType" language="xml">{{ $formatXml(srcFile) }}</prism>
                <iframe v-else
                  :src="srcFile"
                  @load="iFrameLoaded"
                  frameBorder="0"
                  scrolling="auto"
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
              <div v-else class="empty-image center-content">
                <span v-if="!(loadingViewId > 0)">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin archivo cargado</p>
                </span>
                <span v-else><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/></span>
              </div>
            </b-tab>

            <b-tab title="Metadata">
              <!-- <json-viewer :value="JSON.parse(this.metadata)" expanded copyable></json-viewer> -->
              <prism class="line-numbers" language="json">{{ JSON.parse(this.metadata) }}</prism>
            </b-tab>

          </b-tabs>
        </b-card>
      </b-modal>

      <b-modal
        id="bv-modal-viewEstatus" size="xl" centered hide-footer content-class="site-modal"
        :header-text-variant="'light'">
        <template #modal-title>VALIDACIONES {{ nomArchivoSelected }}</template>
        <b-card class="min-padd" no-body>
          <validaciones-box :validaciones="validaciones" />
        </b-card>

      </b-modal>

      <b-modal
      id="bv-modal-estatus" size="lg" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'">
      <template #modal-title>
        Estatus de {{ nomArchivoSelected }}
      </template>
      <form class="form-site inner" @submit.prevent="$validar(['Detalle de Orden']) ? updateEstatus() : ''">
        <div class="form-row">
          <div class="form-holder form-holder-2">
            <fieldset>
              <legend>Estatus</legend>
              <span v-if="!$validar(['Detalle de Orden'])">
                <label class="notation" :style="`border-color:${estatusFile.IconColor}`">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: `${estatusFile.IconEstatus || 'exclamation-circle'}`, }" :style="{color: `${estatusFile.IconColor}`}"/>
                  {{ estatusFile.Estatus }}
                </label>
                <b-form-textarea class="form-control" rows="1" max-rows="5" :value="estatusFile.Observaciones" :plaintext="true" v-if="estatusFile.Observaciones"></b-form-textarea>
              </span>
              <span v-if="$validar(['Detalle de Orden'])">
                <b-dropdown size="sm" class="btn-dropdown" variant="light">
                  <template #button-content>
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: `${estatusSelected.IconEstatus}`, }" :style="{color: `${estatusSelected.IconColor}`}"/>
                    {{ estatusSelected.Estatus }}
                  </template>
                  <b-dropdown-item-button
                    @click="changeEstatusArchivo(estatus)"
                    v-for="estatus in estatusData"
                    :key="estatus.EstatusId">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: `${estatus.IconEstatus}`, }" :style="{color: `${estatus.IconColor}`}"/>
                    {{ estatus.Estatus }}</b-dropdown-item-button>
                </b-dropdown>
                <b-form-textarea class="form-control" rows="1" max-rows="5" placeholder="Observaciones del estatus para mostrar al proveedor" v-model="estatusObserv"></b-form-textarea>
              </span>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block
          v-if="$validar(['Detalle de Orden'])">
          <font-awesome-icon v-if="loadingVal" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loadingVal" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Actualizar estatus
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import { VueGoodTable } from 'vue-good-table';
import ValidacionesBox from '@/components/ValidacionesBox.vue';
import Prism from 'vue-prism-component';
import 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/components/prism-json'; // need this

export default {
  name: 'ArchivosTable',
  components: {
    VueGoodTable,
    ValidacionesBox,
    Prism,
  },
  props: [
    'xmlType',
    'provId',
    'ordenId',
    'tabId',
    'periodo',
    'archivos',
  ],
  data() {
    return {
      loadingViewId: 0,
      loadingDownId: 0,
      loadingDelId: 0,
      loadingVal: false,
      validaciones: [],
      nomArchivoSelected: '',
      srcFile: '',
      metadata: '{}',
      estatusData: {},
      estatusFile: '',
      estatusSelected: '',
      estatusObserv: '',
      columns: [
        {
          label: 'ArchivoId',
          field: 'ArchivoDetId',
          hidden: true,
        },
        {
          label: 'Autor',
          field: 'Autor',
          hidden: true,
        },
        {
          label: 'Fecha',
          field: 'Fecha',
          dateInputFormat: 'dd-mm-yyyy',
          dateOutputFormat: 'yyyy-mm-dd',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Archivo',
          field: 'NomArchivo',
          hidden: this.xmlType,
        },
        {
          label: 'Empleado',
          field: 'NomEmpleado',
          hidden: !this.xmlType,
        },
        {
          label: 'Periodo XML',
          field: 'PeriodoCfdi',
          hidden: !this.xmlType,
        },
        {
          label: 'Estatus',
          field: 'Estatus',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Acciones',
          field: 'Acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '150px',
          hidden: !this.$descargar(['Detalle de Orden']),
        },
      ],
    };
  },
  mounted() {
    this.getEstatus();
  },
  methods: {
    downloadFile(archivoDetId, nomArchivo) {
      this.loadingDownId = archivoDetId;
      apiClient.get(`/orden/${this.ordenId}/archivo`, {
        responseType: 'blob',
        params: {
          ProvId: this.provId,
          archivoDetId,
        },
      })
        .then((res) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(res.data);
          link.download = nomArchivo;
          link.click();
          URL.revokeObjectURL(res.data);
          URL.revokeObjectURL(link.href);
          this.loadingDownId = 0;
        })
        .catch(() => {
          this.loadingDownId = 0;
        });
    },
    viewFile(archivoDetId, metadata) {
      this.loadingViewId = archivoDetId;
      this.metadata = metadata;
      this.srcFile = '';
      apiClient.get(`/orden/${this.ordenId}/archivo`, {
        responseType: this.xmlType ? 'text/xml' : 'blob',
        params: {
          ProvId: this.provId,
          archivoDetId,
        },
      })
        .then((res) => {
          if (this.xmlType) {
            this.srcFile = res.data;
            this.srcFile = this.srcFile.replaceAll('>', '>\n');
          } else {
            this.srcFile = URL.createObjectURL(res.data);
            URL.revokeObjectURL(res.data);
          }
          this.loadingViewId = 0;
        })
        .catch(() => {
          this.loadingViewId = 0;
        });

      this.$bvModal.show('bv-modal-viewfile');
    },
    showDeleteFile(id) {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminará el archivo seleccionado.', {
        title: 'Eliminar archivo',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si, Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        bodyClass: 'max-height-70',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.deleteFile(id);
        })
        .catch(() => {});
    },
    deleteFile(archivoDetId) {
      this.loadingDelId = archivoDetId;
      apiClient.delete(`/orden/${this.ordenId}/archivos`, {
        data: {
          ProvId: this.provId,
          ArchivoDetId: archivoDetId,
        },
      })
        .then(() => {
          this.loadingDelId = 0;
          this.updated();
        })
        .catch(() => {
          this.loadingDelId = 0;
        });
    },
    updated() {
      this.$emit('updated');
    },
    getValidaciones(archivoDetId, nomArchivo) {
      this.nomArchivoSelected = nomArchivo;
      this.validaciones = [];
      apiClient.get(`/orden/${this.ordenId}/validaciones`, {
        params: {
          ProvId: this.provId,
          archivosDetId: [archivoDetId],
        },
      })
        .then((res) => {
          this.validaciones = res.data;
        })
        .catch(() => {});

      this.$bvModal.show('bv-modal-viewEstatus');
    },
    showDeleteAllFiles() {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminarán todos los archivos de esta pestaña.', {
        title: 'Eliminar archivos',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si, Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        bodyClass: 'max-height-70',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.deleteAllFiles();
        })
        .catch(() => {});
    },
    deleteAllFiles() {
      apiClient.delete(`/orden/${this.ordenId}/all/archivos`, {
        data: {
          ProvId: this.provId,
          tabId: this.tabId,
          periodo: this.periodo,
        },
      })
        .then(() => {
          this.updated();
        })
        .catch(() => {
        });
    },
    getEstatus() {
      apiClient.get('/estatus')
        .then((res) => {
          this.estatusData = res.data;
        })
        .catch(() => {});
    },
    getEstatusArchivo(id) {
      apiClient.get(`/orden/${id}/estatus-archivo`, {
        params: {
          ProvId: this.provId,
        },
      })
        .then((res) => {
          this.estatusFile = res.data;
          this.estatusObserv = this.estatusFile.Observaciones;
          this.estatusSelected = this.estatusFile;
          this.$bvModal.show('bv-modal-estatus');
        })
        .catch(() => {});
    },
    openEstatusModal(fileId, nomArchivo) {
      this.nomArchivoSelected = nomArchivo;
      this.getEstatusArchivo(fileId);
    },
    changeEstatusArchivo(estatusSelected) {
      this.estatusSelected = estatusSelected;
    },
    updateEstatus() {
      this.loadingVal = true;
      const { ArchivoDetId } = this.estatusFile;
      const { EstatusId } = this.estatusSelected;
      apiClient.put(`/orden/${this.ordenId}/estatus-archivo`, {
        ProvId: this.provId,
        archivoDetId: ArchivoDetId,
        idEstatus: EstatusId,
        observaciones: this.estatusObserv,
      })
        .then(() => {
          this.updated();
          this.$bvModal.hide('bv-modal-estatus');
          this.loadingVal = false;
        })
        .catch(() => {
          this.loadingVal = false;
        });
    },
    iFrameLoaded() {
      URL.revokeObjectURL(this.srcFile);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
