<template>
  <div>
    <fieldset class="fieldset-dropzone">
      <legend>Subir {{ `${tab.Nombre} (${periodo})` }}</legend>
      <div class="empty-label">
        <span class="title">
          <font-awesome-icon class="icon" :icon="{ prefix: 'fa', iconName: 'exclamation-circle', }" style="color: rgb(187, 111, 36); display: inline-block; opacity: 1;"></font-awesome-icon> AUN NO ES POSIBLE CARGAR
        </span>
        <br>
        <span class="subtitle">La Fecha limite de carga fue el <strong>{{ $fecha_limcarga() }}</strong>. Puede volver a cargar hasta el día <strong>{{ $fecha_libcarga() }}</strong> del mes próximo.</span>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'NoUploadFile',
  props: [
    'tab',
    'periodo',
  ],
};
</script>

<style lang="scss" scoped>
.empty-label {
  background-color: rgb(193, 219, 246);
  border-radius: 3px;
  padding: 10px;
  .title {
    color: rgb(187, 111, 36);
    font-weight: bold;
    font-size: 1rem;
  }
  .subtitle {
    font-size: 0.8rem;
  }
}
</style>
