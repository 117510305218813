<template>
  <div>

    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" title="Regresar a pantalla anterior" @click="goBack()" v-b-popover.hover.bottom>
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }" class="icon btn-icon" />
          </a>
        </transition>
        <transition name="slide-fade" mode="out-in">
          <span>
            <label class="h4 align-sub" :key="title">{{ `${title} (${periodo})` }}</label>
          </span>
        </transition>
      </div>
    </div>

    <orden-data :provId="ProvId" :ordenId="OrdenId" :mostrarOrden="false" :mostrarCarga="false" :updateScreen="updateStatus" @getData="getOrden($event)"/>

    <div class="row">
      <div class="col-12">
        <b-card no-body>
          <div v-if="loading" class="empty-image center-content">
            <span><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Cargando...</span>
          </div>
          <span v-else>

            <b-tabs v-if="tabs" class="tabs-upload" small card lazy>
              <span v-for="tab in tabs" :key="tab.ArchivoTabId">
                <b-tab :title="tab.Nombre">
                  <tab-container :provId="ProvId" :ordenId="OrdenId" :archivoPeriodoId="archivoPeriodoId" :archivoTabId="tab.ArchivoTabId" :periodo="periodo" :tab="tab" @updated="updateScreen($event)" />
                </b-tab>
              </span>
            </b-tabs>

            <div v-else class="empty-image center-content">
              <span v-if="!tabs">
                <img src="@/assets/empty.svg" alt="Empty">
                <p class="empty-description">Sin archivo cargado</p>
              </span>
            </div>

          </span>
        </b-card>
      </div>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import OrdenData from '@/components/OrdenData.vue';
import TabContainer from '@/components/TabContainer.vue';

export default {
  components: {
    OrdenData,
    TabContainer,
  },
  props: [
    'ProvId',
    'OrdenId',
    'archivoPeriodoId',
    'title',
    'periodo',
  ],
  data() {
    return {
      loading: false,
      ordenData: {},
      tabs: '',
      archivos: [],
      validaciones: [],
      riesgos: [],
      updateStatus: false,
    };
  },
  mounted() {
    this.getTabs();
  },
  methods: {
    getTabs(modalMsg = '') {
      this.loading = true;
      apiClient.get(`/orden/${this.OrdenId}/tabs`, {
        params: {
          ProvId: this.ProvId,
          archivoPeriodoId: this.archivoPeriodoId,
        },
      })
        .then((res) => {
          this.tabs = res.data;
          this.loading = false;
          this.createModalMsg(modalMsg);
        })
        .catch(() => {
          this.loading = false;
          this.createModalMsg(modalMsg);
        });
    },
    getOrden(data) {
      this.ordenData = data;
    },
    changedTabs(/* currentTabs */) {
      // console.log(currentTabs);
    },
    clickTab(/* event, tabId */) {
      // console.log(event, tabId);
    },
    updateScreen(modalMsg = '') {
      this.getTabs(modalMsg);
      this.updateStatus = !this.updateStatus;
    },
    goBack() {
      this.$router.push({ name: '/ordenes/detalle', params: { ProvId: `${this.ProvId}`, OrdenId: `${this.OrdenId}` } }).catch(() => {});
    },
    createModalMsg(modalMsg) {
      if (modalMsg.length > 0) {
        const h = this.$createElement;
        // Using HTML string
        const msgVNode = h('div', { domProps: { innerHTML: modalMsg } });
        this.$bvModal.msgBoxOk(msgVNode || '', {
          title: 'Atención',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'OK',
          cancelTitle: 'Cancelar',
          headerClass: 'text-light',
          bodyClass: 'max-height-70',
          footerClass: 'bg-light p-2',
          hideHeaderClose: true,
          centered: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
