<template>
  <div>
    <div v-if="loading" class="empty-image center-content">
      <span><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Cargando...</span>
    </div>
    <span v-else>
      <span v-if="!tab.EsRequerido">
        <no-required-file :periodo="periodo" :tab="tab" />
      </span>
      <span v-else-if="!tab.PuedeCargar">
        <no-upload-file :periodo="periodo" :tab="tab"/>
      </span>
      <span v-else>
        <upload-box
        v-if="$puede_cargar() && (archivos.length === 0 || tab.Multiple) && tab.EsRequerido"
        @submit="updateScreen($event)"
        :provId="provId" :ordenId="ordenId" :periodo="periodo" :tab="tab" />
        <cant-upload-file v-if="!$puede_cargar() && (archivos.length === 0 || tab.Multiple)" :tab="tab" :periodo="periodo" />
        <validaciones-box v-if="tab.EsRequerido || validaciones.length > 0" :validaciones="validaciones" />
        <archivos-table   v-if="tab.EsRequerido || archivos.length > 0" :provId="provId" :ordenId="ordenId" :tabId="tab.ArchivoTabId" :periodo="periodo" :xmlType="tab.Tipo === 'xml'" :archivos="archivos" @updated="updateScreen()" />
      </span>
    </span>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import UploadBox from '@/components/UploadBox.vue';
import ValidacionesBox from '@/components/ValidacionesBox.vue';
import ArchivosTable from '@/components/ArchivosTable.vue';
import NoRequiredFile from '@/components/NoRequiredFile.vue';
import NoUploadFile from '@/components/NoUploadFile.vue';
import CantUploadFile from '@/components/CantUploadFile.vue';

export default {
  name: 'TabContainer',
  components: {
    UploadBox,
    ValidacionesBox,
    ArchivosTable,
    NoRequiredFile,
    NoUploadFile,
    CantUploadFile,
  },
  props: [
    'esRequerido',
    'provId',
    'ordenId',
    'archivoPeriodoId',
    'archivoTabId',
    'periodo',
    'tab',
  ],
  data() {
    return {
      loading: false,
      archivos: [],
      validaciones: [],
      riesgos: [],
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      this.loading = true;
      apiClient.get(`/orden/${this.ordenId}/archivos`, {
        params: {
          ProvId: this.provId,
          archivoTab: this.archivoTabId,
          archivoPeriodoId: this.archivoPeriodoId,
        },
      })
        .then((res) => {
          this.archivos = res.data;
          this.loading = false;
          this.getValidaciones(this.archivos);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getValidaciones(archivos) {
      this.loading = true;
      this.validaciones = [];
      const archivosDetId = archivos.map((archivo) => archivo.ArchivoDetId);
      apiClient.get(`/orden/${this.ordenId}/validaciones`, {
        params: {
          ProvId: this.provId,
          archivosDetId: [...archivosDetId],
        },
      })
        .then((res) => {
          this.validaciones = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateScreen(modalMsg = '') {
      this.getFiles();
      this.$emit('updated', modalMsg);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
