<template>
  <div>
    <fieldset class="fieldset-dropzone">
      <legend>Subir {{ `${tab.Nombre} (${periodo})` }}</legend>

      <VueFileAgent
        ref="vueFileAgent"
        class="dropzone file-agent-scroll"
        :theme="'list'"
        :multiple="tab.Multiple"
        :deletable="true"
        :disabled="loading"
        :meta="true"
        :accept="tab.Extensiones"
        :maxSize="`${tab.PesoMax}MB`"
        :maxFiles="1000"
        :helpText="`${tab.Multiple ? 'SELECCIONE ARCHIVOS A SUBIR' : 'SELECCIONE ARCHIVO A SUBIR'}`"
        :errorText="{
          type: `Archivo no permitido, solo se admiten extensiones: ${tab.Extensiones}`,
          size: `El archivo no debe exceder ${tab.PesoMax}MB`,
        }"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        v-model="files"
      ></VueFileAgent>

      <div class="row">
        <div class="col-12">
          <label class="text-note"> {{ `Tamaño máximo permitido por archivo&nbsp;${tab.PesoMax} MB, Extensiones&nbsp;${tab.Extensiones}` }}</label>
        </div>
        <div class="col-12 mb-2">
          <b-progress v-if="progress > 0" :max="100" show-progress animated>
            <b-progress-bar :value="progress" :label-html="`<strong>Validando ${progress}%</strong>`"></b-progress-bar>
          </b-progress>
        </div>
        <div class="col-12 text-right">
          <b-button class="btn-detail" @click="uploadFiles" :disabled="(loading || !this.filesToUpload.length) ? true : false">
            <span v-if="loading"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Enviando {{ this.filesToUpload.length }} archivo(s)</span>
            <span v-if="!loading"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'paper-plane', }" class="icon btn-icon"/> Enviar {{ this.filesToUpload.length }} archivo(s)</span></b-button>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';

export default {
  name: 'UploadBox',
  props: [
    'provId',
    'ordenId',
    'periodo',
    'tab',
  ],
  data() {
    return {
      loading: false,
      files: [],
      filesToUpload: [],
      filesError: [],
      progress: 0,
    };
  },
  methods: {
    filesSelected(files) {
      // this.filesError = [];

      if (files.length) {
        const validFileRecords = files.filter((fileRecord) => !fileRecord.error);
        if (validFileRecords.length) this.filesToUpload.push(...validFileRecords);
      }
    },
    onBeforeDelete(file) {
      this.files = this.files.filter((fileRecord) => fileRecord.name() !== file.name());
      this.filesToUpload = this.filesToUpload.filter((fileRecord) => fileRecord.name() !== file.name());
    },
    uploadFiles() {
      if (this.filesToUpload.length === 0) return;
      const files = this.filesToUpload.map((file) => file.file);
      this.loading = true;
      let count = 0;
      Promise.all(files.map((file) => {
        // Formulario para envio de archivo con data
        const formData = new FormData();
        const endpoint = this.tab.Multiple ? `/orden/${this.ordenId}/cfdis` : `/orden/${this.ordenId}/archivos`;
        formData.append('File', file);
        formData.append('ArchivoTabId', this.tab.ArchivoTabId);
        formData.append('Periodo', this.$formatDate(this.$mxToDate(`01-${this.periodo}`)));
        formData.append('ProvId', this.provId);
        return apiClient.post(endpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        }).finally(() => {
          count += 1;
          this.progress = Math.round((count * 100) / this.filesToUpload.length);
        });
      })).then((res) => {
        let errores;
        res.forEach((response) => { if (response.status === 202) errores = (errores ? `${errores}<br>❌${response.data.ms}` : `❌${response.data.ms}`); });
        // this.getFilesData();
        this.loading = false;
        this.successfulLoaded(errores);
      }).catch((err) => {
        this.loading = false;
        this.progress = 0;
        this.$bvModal.msgBoxOk(err.response.data.ms || '', {
          title: err.response.data.title || 'Atención',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'OK',
          cancelTitle: 'Cancelar',
          headerClass: 'text-light',
          bodyClass: 'max-height-70',
          footerClass: 'bg-light p-2',
          hideHeaderClose: true,
          centered: true,
        });
        // this.successfulLoaded();
      });
    },
    successfulLoaded(modalMsg = '') {
      this.$emit('submit', modalMsg);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-agent-scroll {
  max-height: 20vh;
  overflow: auto;
}
</style>
